/**
 * Send ajax request to the Magento store in order to insert dynamic content into the
 * static page delivered from Varnish
 *
 * @author Fabrizio Branca
 * @author Bastian Ike
 */

import { cookiesArray, ready } from './polyfills.js'

var Aoe_Static = {
    storeId: null,
    websiteId: null,
    fullActionName: null,
    ajaxHomeUrl: null,
    currentProductId: null,

    init: function (
        ajaxhome_url,
        fullactionname,
        storeId,
        websiteId,
        currentproductid
    ) {
        this.storeId = storeId
        this.websiteId = websiteId
        this.fullActionName = fullactionname
        this.ajaxHomeUrl = ajaxhome_url
        this.currentProductId = currentproductid

        this.populatePage()
    },

    /**
     * populate page
     */
    populatePage: function () {
        this.replaceCookieContent()
        this.replaceAjaxBlocks()
        if (this.isLoggedIn()) {
            if (document.querySelector('.aoestatic_notloggedin')) {
                document.querySelector('.aoestatic_notloggedin').style.display =
                    'none'
            }
            if (document.querySelector('.aoestatic_loggedin')) {
                document.querySelector('.aoestatic_loggedin').style.display =
                    'block'
            }
        } else {
            if (document.querySelector('.aoestatic_loggedin')) {
                document.querySelector('.aoestatic_loggedin').style.display =
                    'none'
            }
            if (document.querySelector('.aoestatic_notloggedin')) {
                document.querySelector('.aoestatic_notloggedin').style.display =
                    'block'
            }
        }
    },

    /**
     * Replace cookie content
     */
    replaceCookieContent: function () {
        document.documentElement.dispatchEvent(
            new Event('aoestatic_beforecookiereplace')
        )
        Array.prototype.forEach.call(
            this.getCookieContent(),
            function (name, value) {
                document.querySelector('.aoestatic_' + name).innerText = value
            }
        )
        document.documentElement.dispatchEvent(
            new Event('aoestatic_aftercookiereplace')
        )
    },

    isLoggedIn: function () {
        var cookieValues = this.getCookieContent()
        return (
            typeof cookieValues['customername'] != 'undefined' &&
            cookieValues['customername'].length
        )
    },

    /**
     * Get info from cookies
     */
    getCookieContent: function () {
        // expected format as_[g|w<websiteId>|s<storeId>]
        var values = {}
        Array.prototype.forEach.call(cookiesArray(), function (name, value) {
            if (name.substr(0, 10) === 'aoestatic_') {
                name = name.substr(10)
                var parts = name.split('_')
                var scope = parts.splice(0, 1)[0]
                name = parts.join('_')
                if (name && scope) {
                    if (typeof values[name] == 'undefined') {
                        values[name] = {}
                    }
                    values[name][scope] = value
                }
            }
        })

        var cookieValues = {}
        Array.from(values).forEach(function (name, data) {
            if (typeof data['s' + Aoe_Static.storeId] != 'undefined') {
                cookieValues[name] = data['s' + Aoe_Static.storeId]
            } else if (typeof data['w' + Aoe_Static.websiteId] != 'undefined') {
                cookieValues[name] = data['w' + Aoe_Static.websiteId]
            } else if (typeof data['g'] != 'undefined') {
                cookieValues[name] = data['g']
            }
        })
        return cookieValues
    },

    /**
     * Load block content from server
     */
    replaceAjaxBlocks: function () {
        ready(function () {
            var data = {
                getBlocks: {}
            }

            // add placeholders
            var counter = 0
            var asPlaceholders = document.querySelectorAll('.as-placeholder')
            Array.from(asPlaceholders).forEach(function (el) {
                var id = el.getAttribute('id')
                if (!id) {
                    // create dynamic id
                    id = 'ph_' + counter
                    el.setAttribute('id', id)
                }
                var rel = el.getAttribute('rel')
                if (rel) {
                    if (localStorage.getItem('aoe_static_blocks_' + rel)) {
                        document.getElementById(
                            id
                        ).innerHTML = localStorage.getItem(
                            'aoe_static_blocks_' + rel
                        )
                    }
                    if (rel === 'hellobar_content') {
                        const customData = JSON.stringify(hellobarData)
                        data.getBlocks[id] = `hellobar_content&customData[hellobar]=` + customData
                    }
                    else if (rel === 'formkey') {
                        data.getBlocks['formkey'] = rel
                    }
                    else {
                        data.getBlocks[id] = rel
                    }
                    counter++
                } else {
                    throw 'Found placeholder without rel attribute'
                }
            })
            if (Aoe_Static.currentProductId) {
                data.currentProductId = Aoe_Static.currentProductId
            }
            // E.T. phone home, get blocks and pending flash-messages
            var ajax = new XMLHttpRequest()
            ajax.addEventListener('readystatechange', function () {
                if (ajax.readyState === 4) {

                    if (ajax.status != 200) {
                        if (ajax.responseURL.includes('hellobar')) {
                            let hellobar = document.getElementById('hellobar')
                            hellobar.classList.add('is-hidden')
                        }
                    }

                    const responseData = JSON.parse(ajax.response)

                    for (let id in responseData.blocks) {
                        if (id === 'formkey') {
                            const formkeyBlocks = document.querySelectorAll('.as-placeholder[rel="formkey"]')
                            Array.from(formkeyBlocks).forEach(function (el) {
                                el.innerHTML = responseData.blocks['formkey']
                            })
                        }
                        else {
                            if (id === 'hellobar') {
                                if (responseData.blocks[id].length === 0) {
                                    let hellobar = document.getElementById('hellobar')
                                    hellobar.classList.add('is-hidden')
                                }
                            }
                            if (data.getBlocks[id] == 'product_pharmacy_blocked' && responseData.blocks[id] != '') {
                              document.getElementById('product-add-to-cart').innerHTML = responseData.blocks[id]
                              document.getElementById('product-data__information information').innerHTML = '' +
                                '<p>Dostępność:</p>' +
                                '<p class="product-out__stock">Produkt chwilowo niedostępny </p>';
                            } else {
                              document.getElementById(id).innerHTML = responseData.blocks[id]
                              localStorage.setItem(
                                'aoe_static_blocks_' + data.getBlocks[id],
                                responseData.blocks[id]
                              )
                            }
                        }

                      setTimeout(() => {
                        document.documentElement.dispatchEvent(
                          new CustomEvent('aoestatic_afterspecifiedblockreplace', {detail: document.getElementById(id)})
                        )
                      }, 0)
                    }
                    document.documentElement.dispatchEvent(
                        new Event('aoestatic_afterblockreplace')
                    )
                }
            })

            let params = Object.keys(data.getBlocks)
                .map((key) => `getBlocks[${key}]` + '=' + data.getBlocks[key])
                .join('&')
            if (Aoe_Static.currentProductId) {
                params = `${params}&currentProductId=${Aoe_Static.currentProductId}`
            }

            ajax.open('GET', `${Aoe_Static.ajaxHomeUrl}?${params}&isAjax=1`)
            ajax.send()
        })
    }
}

window.Aoe_Static = Aoe_Static
